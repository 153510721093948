import React, { useState, useMemo } from 'react';
import axios, {AxiosError} from 'axios';

export type ErrorType = AxiosError | undefined;

export interface ApiErrorContextType {
    error: ErrorType,
    addError: (error: ErrorType) => void,
    removeError: () => void
}

export const ApiErrorContext = React.createContext<ApiErrorContextType>({
    error: undefined,
    addError: () => undefined,
    removeError: () => undefined
});

export default function ApiErrorHandler({ children }: { children: React.ReactElement } ): JSX.Element {
    const [error, setError] = useState<ErrorType>();

    const removeError = () => setError(undefined);

    const addError = (error: ErrorType) => setError(error);

    const contextValue = useMemo(() => ({
        error,
        addError: (error: ErrorType) => addError(error),
        removeError: () => removeError()
    }), [error]);

    axios.interceptors.response.use((response) => response, (error) => {
        addError(error);
        return Promise.reject(error);
    });
    
    return (
        <ApiErrorContext.Provider value={contextValue}>
            { children }
        </ApiErrorContext.Provider>
    );
}

export const useApiErrorHandler: () => ApiErrorContextType = () => React.useContext(ApiErrorContext);